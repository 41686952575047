import "core-js/modules/es.object.keys.js";
import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
export default {
  name: "qualificationsDialog",
  components: {},
  data: function data() {
    return {
      payMethodshow: false,
      userInfo: accountStorage.getCacheUserInfo(),
      expiredQualification: {},
      goodsItem: null
    };
  },
  methods: {
    closeModal: function closeModal() {
      this.payMethodshow = false;
    },
    showModal: function showModal(goodsItem) {
      this.payMethodshow = true;
      this.expiredQualification = JSON.parse(localStorage.getItem('qualificationOverdueData')) || {};
      this.goodsItem = goodsItem;
    },
    // 继续加购
    continueToAddPurchases: function continueToAddPurchases() {
      this.$emit('continueToAddPurchases', this.goodsItem);
      this.closeModal();
    }
  }
};