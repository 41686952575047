import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.payMethodshow ? _c('div', {
    staticClass: "qualifications-dialog-bg"
  }, [_c('div', {
    staticClass: "qualifications-dialog-wrap"
  }, [_c('div', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "section_left"
  }, [_c('div', {
    staticClass: "active_logo"
  }), _c('span', {
    staticClass: "similar_text"
  }, [_vm._v(_vm._s(_vm.expiredQualification.title))])]), _c('div', {
    staticClass: "section_right"
  }, [_c('i', {
    staticClass: "el-icon-error",
    on: {
      "click": _vm.closeModal
    }
  })])]), _c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "qualifications-hint-wrap"
  }, _vm._l(_vm.expiredQualification.certificateVOList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "qualifications-hint-wrap_item"
    }, [item.expireDayNumber > 0 ? _c('span', [_vm._v(" 《" + _vm._s(item.name) + "》将在" + _vm._s(item.expireDayNumber) + "天后" + _vm._s(item.expireDay) + " 过期 ")]) : _vm._e(), item.expireDayNumber == 0 ? _c('span', [_vm._v(" 《" + _vm._s(item.name) + "》即将过期 ")]) : _vm._e(), item.expireDayNumber < 0 ? _c('span', [_vm._v(" 《" + _vm._s(item.name) + "》已于" + _vm._s(item.expireDay) + "过期，已过期" + _vm._s(item.expireDayNumber * -1) + "天 ")]) : _vm._e()]);
  }), 0), _c('div', {
    staticClass: "modal-hint"
  }, [_vm._v(_vm._s(_vm.expiredQualification.content))]), _c('div', {
    staticClass: "modal-hint",
    staticStyle: {
      "margin-top": "10px",
      "margin-bottom": "20px"
    }
  }, [_vm._v("商务" + _vm._s(_vm.expiredQualification.name) + "：" + _vm._s(_vm.expiredQualification.phone))]), _vm.expiredQualification.typeOfQualification == 1 ? _c('div', {
    staticClass: "modal-btn-wrap"
  }, [_c('div', {
    staticClass: "modal-btn",
    on: {
      "click": _vm.continueToAddPurchases
    }
  }, [_vm._v("继续加购")])]) : _vm._e()])])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };