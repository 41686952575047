import { AccountStorage } from "@services/account";
const accountStorage = new AccountStorage();

export default {
  data() {
    return {
      modalInfo: JSON.parse(localStorage.getItem('qualificationOverdueData')) || {},  // 资质过期信息
    }
  },

  methods: {
    // 客户临期资质列表接口
    async wilExpireList() {
      if (!accountStorage.getCacheUserInfo()) return;
      let { loginCompanyId } = accountStorage.getCacheUserInfo();
      let api = `/pjyy-deepexi-user-center/api/v1/certificate/wilExpireList/${loginCompanyId}`;
      const res = await this.$getHttpClient({useSpinner: false}).get(api);
      let { code, data } = res.data;
      if (code == 200) {
        // 是否有负数
        let isNegativeNumber = data.certificateVOList.some(item => item.expireDayNumber < 0);
        let content = null;
        if (isNegativeNumber) {
          content = '故本上商品不可下单，为不影响您正常下单，请尽快更新资质，或联系商务代理更新';
        } else {
          content = "为不影响您正常下单，请尽快更新资质，或联系商务代理更新。";
        }
        this.modalInfo = {
          typeOfQualification: isNegativeNumber ? 2 : 1,  // 1.资质临期  2.资质过期
          title: isNegativeNumber ? '资质过期提醒' : '资质临期提醒',
          content: content,
          name: data.staffName,
          phone: data.staffTel,
          certificateVOList: data.certificateVOList,
          numberOfDaysExpired: data.certificateVOList.map(item => item.expireDayNumber),
          isPopups: data.isPopups,
          btnName1: "我要更新",
          btnName2: "联系商务更新",
        }
        localStorage.setItem('qualificationOverdueData', JSON.stringify(this.modalInfo))
      }
    },

    // 弹出资质弹窗判断  type：1.资质临期  2.资质过期
    qualificationsPopup(type, data, goodsItem) {
      if (type == 1) {
        let qualificationExpiry = JSON.parse(localStorage.getItem('qualificationOverdueData'))
        if (qualificationExpiry) {
          let numberOfDaysExpired = qualificationExpiry.numberOfDaysExpired;
          // isPopups 后端处理逻辑，根据资质临期天数是否在 30天/15天/7天 这三天，如果是则返回true，且当天只返回一次true
          if (qualificationExpiry.isPopups) {
            this.wilExpireList()
            this.$refs.qualificationsDialogRef.showModal(goodsItem)
            return true;
          }
        }
      } else if (type == 2) {
        let expiredQualifications = {
          typeOfQualification: 2,  // 1.资质临期  2.资质过期
          title: '资质过期提醒',
          content: '故本上商品不可下单，为不影响您正常下单，请尽快更新资质，或联系商务代理更新',
          name: data.staffName,
          phone: data.staffTel,
          certificateVOList: data.certificateList,
          numberOfDaysExpired: data.certificateList.map(item => item.expireDayNumber),
          isPopups: data.isPopups,
          btnName1: "我要更新",
          btnName2: "联系商务更新",
        }
        localStorage.setItem('qualificationOverdueData', JSON.stringify(expiredQualifications))
        this.$refs.qualificationsDialogRef.showModal()
      }
    },

    // 继续添加购物车
    continueToAddPurchases(goodsItem) {
      this.addCard && this.addCard(goodsItem)
      this.addShopping && this.addShopping(goodsItem)
      this.addCart && this.addCart();
    }
  }
}
